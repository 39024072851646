/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

 (function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {

        if (jQuery('#back-to-top').length) {
					var scrollTrigger = 100, // px
          backToTop = function () {
           var scrollTop = jQuery(window).scrollTop();
           if (scrollTop > scrollTrigger) {
            jQuery('#back-to-top').addClass('show-scroll');
          } else {
            jQuery('#back-to-top').removeClass('show-scroll');
          }
        };
        backToTop();
        jQuery(window).on('scroll', function () {
          backToTop();
        });
        jQuery('#back-to-top').on('click', function (e) {
          e.preventDefault();
          jQuery('html,body').animate({
           scrollTop: 0
         }, 700);
        });
      }

      jQuery('a[href^="#"]').click(function (e) {
       e.preventDefault();
       var $dom = jQuery('html, body');
       $dom.animate({
        scrollTop: jQuery($.attr(this, 'href')).offset().top
      }, 1000);

       return false;
     });

        // JavaScript to be fired on all pages, after page specific JS is fired
        jQuery('.job-item').on('click', function (e) {
         e.preventDefault();
         link = jQuery(this).find('a').attr('href');
         var $dom = jQuery('html, body');
         $dom.animate({
           scrollTop: jQuery(link).offset().top
         }, 1000);
       });

        jQuery('.match-height').matchHeight();
        jQuery('.match-height-2').matchHeight();
        jQuery('.match-height-3').matchHeight();

        jQuery('.owl-partners').owlCarousel({
         nav: false,
         loop: true,
         margin: 50,
         items: 6,
         autoplay: true,
         smartSpeed: 1000,
         autoplayTimeout: 2000,

         responsive: {
          0: {
           items: 1
         },
         600: {
           items: 3
         },
         1000: {
           items: 5
         }
       }
     });

        jQuery('.news-item, .blog-item').on('click', function (e) {
         e.preventDefault();
         link = jQuery(this).find('a').attr('href');
         if(link) {
          window.location.href = link;
        } else {
          return false;
        }
      });

				//JS pour  les boutons file input
				jQuery(document).on('change', ':file', function () {
					var input = jQuery(this),
          numFiles = input.get(0).files ? input.get(0).files.length : 1,
          label = input.val().replace(/\\/g, '/').replace(/.*\//, '');
          input.trigger('fileselect', [numFiles, label]);
        });

				// We can watch for our custom `fileselect` event like this
				jQuery(document).ready(function () {
					jQuery(':file').on('fileselect', function (event, numFiles, label) {

						var input = jQuery(this).parents('.input-group').find(':text'),
           log = numFiles > 1 ? numFiles + ' files selected' : label;

           if (input.length) {
             input.val(log);
           } else {
             if (log) {
              alert(log);
            }
          }

        });
				});

        $('.menu-item-has-children').on('click', function(e) {
          var dropdown = $(this).find('> .dropdown-menu');
          if (!dropdown.hasClass('active')) {
            $('.dropdown-menu.active').removeClass('active');
          }
          dropdown.toggleClass('active');
          e.preventDefault();
        });

        $('.menu-item:not(.menu-item-has-children)').on('click', function(e) {
          window.location = $(this).find('a').attr('href');
          e.preventDefault();
        })

        // gmap
        var markers = [];
        if ($('#gmap').length > 0) {
          var map = new window.google.maps.Map(
            document.getElementById('gmap'), {zoom: 7, center: { lat:49.1029727, lng:6.2285978}, disableDefaultUI: 1, styles: [{'elementType':'geometry','stylers':[{'color':'#f5f5f5'}]},{'elementType':'labels.icon','stylers':[{'visibility':'on'}]},{'elementType':'labels.text.fill','stylers':[{'color':'#616161'}]},{'elementType':'labels.text.stroke','stylers':[{'color':'#f5f5f5'}]},{'featureType':'administrative.land_parcel','elementType':'labels.text.fill','stylers':[{'color':'#bdbdbd'}]},{'featureType':'poi','elementType':'geometry','stylers':[{'color':'#eeeeee'}]},{'featureType':'poi','elementType':'labels.text.fill','stylers':[{'color':'#757575'}]},{'featureType':'poi.park','elementType':'geometry','stylers':[{'color':'#e5e5e5'}]},{'featureType':'poi.park','elementType':'labels.text.fill','stylers':[{'color':'#9e9e9e'}]},{'featureType':'road','elementType':'geometry','stylers':[{'color':'#ffffff'}]},{'featureType':'road.arterial','elementType':'labels.text.fill','stylers':[{'color':'#757575'}]},{'featureType':'road.highway','elementType':'geometry','stylers':[{'color':'#dadada'}]},{'featureType':'road.highway','elementType':'labels.text.fill','stylers':[{'color':'#616161'}]},{'featureType':'road.local','elementType':'labels.text.fill','stylers':[{'color':'#9e9e9e'}]},{'featureType':'transit.line','elementType':'geometry','stylers':[{'color':'#e5e5e5'}]},{'featureType':'transit.station','elementType':'geometry','stylers':[{'color':'#eeeeee'}]},{'featureType':'water','elementType':'geometry','stylers':[{'color':'#c9c9c9'}]},{'featureType':'water','elementType':'labels.text.fill','stylers':[{'color':'#9e9e9e'}]}]});

          var locations = [
          {
            address: "Cabinet d’Expertise comptable et de conseil LORGEC METZ<br>Société LORGEC SYNERGIES",
            lat: 49.1029727,
            lng: 6.2285978
          },
          {
            address: "Cabinet d’Expertise comptable et de conseil LORGEC Toul-Ecrouves",
            lat: 48.6805213,
            lng: 5.8619313
          },
          {
            address: "Société de Commissariat aux Comptes HBM Audit et Révision",
            lat: 49.1029762,
            lng: 6.2264091
          },
          {
            address: "Cabinet d’Expertise comptable et de conseil PREVOT-LORGEC ÉPINAL",
            lat: 48.2033258,
            lng: 6.4582069
          },
          {
            address: "Cabinet d’Expertise comptable et de conseil LORGEC NANCY",
            lat: 48.6944005,
            lng: 6.1836115
          },
          {
            address: "ECCA Sarl Fiduciaire & Family Office",
            lat: 49.581369,
            lng: 6.1136298
          }
          ];

          jQuery(locations).each(function() {

           var address = this.address;
           var infowindow = new google.maps.InfoWindow({
            content: address
          });

           var marker = new google.maps.Marker({
            position: this,
            map: map,
            title: this.address,
            animation: google.maps.Animation.DROP,
          });

           markers.push(marker);

           marker.addListener('click', function() {
            infowindow.open(map, marker);
          });
         });

          var markerCluster = new MarkerClusterer(map, markers,
            {imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'});

        }

        function toggleBounce() {
          if (marker.getAnimation() !== null) {
            marker.setAnimation(null);
          } else {
            marker.setAnimation(google.maps.Animation.BOUNCE);
          }
        }
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  jQuery(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
